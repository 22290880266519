import React from "react"
import Layout from "../components/layout"
import { Container } from "react-bootstrap"
import Seo from "../components/Seo/seo"
export default function Terms() {
  return (
    <Layout>
      <Seo title="Terms and Conditions" />
      <Container>
        <h1 className="my-5 text-uppercase">Terms and conditions</h1>
        <strong>Travel provider</strong>
        <p>
          The tour operator is Tarjeta de Viajero Centroamérica SA, San José,
          Costa Rica. Céd. Jur. 3-101-149-124.
        </p>
        <strong>Formation of the contract</strong>
        <p>
          The contract for the trip is concluded after receipt of the total
          amount before the deadline and confirmation of the trip by Tarjeta de
          Viajero Centroamérica SA, San José, Costa Rica. A receipt for the
          total amount paid will be issued in Costa Rica by the travel agency.
          These will be given to the tour guide by the travel agency and can be
          obtained from the tour guide. The legal regulations of Germany apply.
        </p>
        <strong>Minimum number of participants</strong>
        <p>
          A minimum number of 6-10 participants (depending on the season) is
          required for the trip. If the number of participants falls below this
          number, the organizer has the right to cancel the trip. Likewise, the
          organizer can deny the trip to participants if the maximum number of
          participants for the trip has already been reached. The participants
          will be informed about this no later than 2 weeks after the
          registration deadline. In case of cancellation due to not reaching the
          minimum number of participants or exceeding the maximum number of
          participants, already paid amounts will be fully refunded by the
          organizer. Further claims do not arise.
        </p>
        <strong>Duties of the participants</strong>
        <p>
          For travelers of German nationality, visa information is included in
          this document. Please refer to the foreign ministry of your country
          for the latest updates on travel regulations. The participant is
          responsible for any further visa requirements. Likewise it is
          incumbent on the participant to take care of health precautions,
          inoculations, foreign health insurance and the like. Information about
          this can also be found in this text.
        </p>
        <strong>Withdrawal from the trip</strong>
        <p>
          If the trip cannot be started due to unforeseen reasons, the tour
          operator will refund the paid tour price minus services already
          received (e.g. preparatory workshops) and costs already incurred (e.g.
          reservations).
        </p>
        <strong>Travel conditions USA</strong>
        <p>
          Special conditions apply to transit travel via the USA. An online
          registration must be completed prior to departure. This is possible at
          https://esta.cbp.dhs.gov, where the German language can also be
          selected for filling out the form. At the transit airport,
          fingerprints will be scanned, a digital portrait photo will be taken,
          and after another security check and the independent receipt and
          re-check-in of baggage, hopefully things will go smoothly. Current
          travel advice and travel regulations are provided by the German
          Foreign Office.
        </p>
        <strong>Travel conditions Costa Rica</strong>
        <p>
          A passport is required for entry into Costa Rica. A visa valid for
          30-90 days will be issued at the airport of entry. When departing from
          an airport, a $26 fee is required and must be paid prior to check-in
          at the Costa Rica airport of departure. For other border crossings
          into Costa Rica's neighboring countries, it is worthwhile to have
          printed copies of the flight confirmations/airline tickets with you,
          otherwise you may be required to purchase an additional ticket
          (usually a bus ticket) to leave the country. Further and up-to-date
          travel and vaccination advice is provided by the German Foreign Office
          for German nationals.
        </p>
        <strong>Force Majeure</strong>
        <p>
          Should the trip be considerably impeded, endangered or impaired by
          unforeseeable force majeure (e.g. war, riots, natural disasters,
          etc.), both the organizer and the participant can withdraw from the
          trip. The regulations under 'Withdrawal from the trip' apply. If such
          an event occurs during the trip, the organizer's obligation for return
          transportation is limited to the place where the round trip started,
          usually San Jose Airport (SJO). Additional costs for return
          transportation are borne by both the organizer and the participant,
          half each. All other additional costs will be borne by the
          participant.
        </p>
        <strong>Liability</strong>
        <p>
          vLiability for damages, which are not bodily injuries, are limited to
          three times the travel price, as far as a damage of the traveler was
          caused neither intentionally nor grossly negligent and as far as it
          does not concern the injury of substantial contract obligations. For
          claims for damages regarding property damage, which have their cause
          in a culpably committed prohibited act, Tarjeta de Viajero
          Centroamérica SA is liable per customer and trip, as far as a damage
          was not caused intentionally or by gross negligence, up to 4.091€ or
          up to three times the trip price paid to Tarjeta de Viajero
          Centroamérica SA.
        </p>
        <strong>Traveling with Children</strong>
        <p>
          Should children be registered for the tour, we are happy to welcome
          them. However, participation in the trip is only possible if legal
          representatives agree and at least one guardian accompanies the
          underage participant.
        </p>
        <strong>Insurances</strong>
        <p>
          The travel price does not include travel insurances (e.g. for travel
          cancellation costs, return transport, (foreign) health insurance,
          etc.). It is therefore recommended that you take out private insurance
          to cover these and other eventualities.
        </p>
        <strong>Severability clause</strong>
        <p>
          Should any provision of the contract be invalid, the validity of the
          remaining provisions shall not be affected. The contracting parties
          undertake to replace an invalid provision with a valid provision that
          comes as close as possible to the invalid provision.
        </p>
      </Container>
    </Layout>
  )
}
